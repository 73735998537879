import React from 'react'
import { graphql, navigate } from 'gatsby'
import Helmet from 'react-helmet'
import { Formik } from 'formik'
import Layout from '../components/layout'
import TopSocial from '../components/top-social'

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

class ContactPage extends React.Component {
  render() {
    const contact = this.props.data.contentfulContactPage
    let sectionCount = 0
    return (
      <Layout location={this.props.location}>
        <Helmet title={contact.title} />
        <TopSocial
          heroImage={contact.heroImage.gatsbyImageData}
          heroTitle={contact.heroTitle}
        />
        <section>
          <header>
            <p className="section-number">0{(sectionCount += 1)}</p>
            <h2 id="contact">{contact.title}</h2>
          </header>
          <div
            className="section-description mt"
            dangerouslySetInnerHTML={{
              __html: contact.contactBlurb.childMarkdownRemark.html,
            }}
          />
          <div className="section-description mt-half full-width form-wrapper">
            <Formik
              initialValues={{ name: '', email: '', subject: '', message: '' }}
              validate={(values) => {
                const errors = {}
                if (!values.name) {
                  errors.name = 'Required'
                } else if (!values.email) {
                  errors.email = 'Required'
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                  errors.email = 'Invalid email address'
                } else if (!values.subject) {
                  errors.subject = 'Required'
                } else if (!values.message) {
                  errors.message = 'Required'
                }
                return errors
              }}
              onSubmit={(values, { setSubmitting }) => {
                fetch('/', {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                  },
                  body: encode({
                    'form-name': 'contact',
                    ...values,
                  }),
                })
                  .then(() => {
                    navigate('/thanks/')
                  })
                  .catch((error) => alert(error))
                setSubmitting(false)
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <form
                  action="/thanks/"
                  data-netlify="true"
                  method="post"
                  name="contact"
                  netlify-honeypot="bot-field"
                  onSubmit={handleSubmit}
                >
                  <input type="hidden" name="form-name" value="contact" />
                  <p className="visually-hidden">
                    <label>
                      Leave this field blank:{' '}
                      <input name="bot-field" tabIndex="-1" />
                    </label>
                  </p>
                  <div className="field half-width pr-half-rem">
                    <div className="error">
                      {errors.name && touched.name && errors.name}
                    </div>
                    <input
                      name="name"
                      placeholder="Full Name*"
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                    />
                  </div>
                  <div className="field half-width pr-half-rem">
                    <div className="error">
                      {errors.email && touched.email && errors.email}
                    </div>
                    <input
                      type="email"
                      name="email"
                      placeholder="Email Address*"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                    />
                  </div>
                  <div className="field full-width">
                    <div className="error">
                      {errors.subject && touched.subject && errors.subject}
                    </div>
                    <input
                      type="text"
                      name="subject"
                      placeholder="Subject*"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.subject}
                    />
                  </div>
                  <div className="field full-width">
                    <div className="error">
                      {errors.message && touched.message && errors.message}
                    </div>
                    <textarea
                      name="message"
                      placeholder="Message*"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.message}
                    />
                  </div>
                  <button
                    className="button mobile-full"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Send
                  </button>
                </form>
              )}
            </Formik>
          </div>
        </section>
      </Layout>
    )
  }
}

export default ContactPage

export const pageQuery = graphql`
  query ContactPageQuery {
    contentfulContactPage(id: { eq: "82d91a4d-43f2-5c7d-b826-03d4e712354b" }) {
      contactBlurb {
        childMarkdownRemark {
          html
        }
      }
      heroTitle
      heroImage {
        gatsbyImageData(width: 1122, layout: CONSTRAINED)
      }
      title
    }
  }
`
